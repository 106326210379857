export const compareArrays = (a: unknown[], b: unknown[]) =>
  a.length === b.length && a.every((value) => b.indexOf(value) > -1)

export const removeIndex = <T>(arr: Array<T>, index: number): Array<T> => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export const modifyAtIndex = <T>(
  arr: Array<T>,
  index: number,
  value: T
): Array<T> => {
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)]
}

export const overlaps = <T>(a: T[], b: T[]) => {
  return a.some((value) => b.includes(value))
}
